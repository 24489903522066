import { Switch } from "antd";
import { DeleteFilled, EditFilled, HolderOutlined } from '@ant-design/icons';
import { ProjectModel } from "../../models/projectModel";
import { useEffect, useRef, useState } from "react";
import {TYPE_CHANGE_INPUT} from "../../enum";

interface Props {
  listProject: ProjectModel[];
  removeProject: (record: ProjectModel) => void;
  gotoDetail: (record: ProjectModel) => void;
  loading: boolean;
  onChangeStatus: (record: ProjectModel) => void;
  updateList: (data: ProjectModel[]) => void;
}

const TableProjectList = (props: Props) => {
  const { listProject, removeProject, gotoDetail, onChangeStatus, updateList } = props;
  const [listProjectSort, setList] = useState(listProject);

  useEffect(() => {
    setList(listProject);
  }, [listProject]);

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const _renderActive = (record: ProjectModel) => {
    return (
      <div className={'button-group-active'}>
        <EditFilled className={'icon-edit'} onClick={() => gotoDetail(record)} />
        <DeleteFilled onClick={() => removeProject(record)} />
      </div>
    )
  };

  const _renderSort = () => {
    return (
      <div className={'button-group-active'}>
        <HolderOutlined />
      </div>
    )
  };

  const _renderStatus = (record: ProjectModel) => {
    return (
      <div className={'button-group-active'}>
        <Switch value={record.status} onChange={() => onChangeStatus(record)} />
      </div>
    )
  };

  const _renderColumn = (values: string) => {
    return (
      <p className={'style-text-limit-number-line1 text-column-value'}>{values}</p>
    )
  };

  const handleSort = () => {
    let listData = listProjectSort;
    const dragItemNew = listData.splice(dragItem.current, 1)[0]
    listData.splice(dragOverItem.current, 0, dragItemNew)
    dragItem.current = null
    dragOverItem.current = null;
    setList([...listData]);
    updateList([...listData]);
  };

  const _renderRow = () => {
    return (
      <div className="row-project-container2">
        <div className="column-action">SORT</div>
        <div className="column-title2">{TYPE_CHANGE_INPUT.CLIENT}</div>
        <div className="column-title2">{TYPE_CHANGE_INPUT.SERVICES}</div>
        <div className="column-title2">{TYPE_CHANGE_INPUT.EXPERIMENT_TITLE}</div>
        <div className="column-title2">{TYPE_CHANGE_INPUT.EXPERIMENT_DESCRIPTION}</div>
        <div className="column-title2">{TYPE_CHANGE_INPUT.THUMBNAIL}</div>
        <div className="column-title">{TYPE_CHANGE_INPUT.IMAGE}</div>
        <div className="column-action">STATUS</div>
        <div className="column-action">ACTION</div>
      </div>
    )
  };

  const _renderRowValue = (item: ProjectModel, index: number) => {
    return (
      <div
        className="row-project-container"
        draggable='true'
        onDragStart={(e) => {
          dragItem.current = index
        }}
        onDragEnter={(e) => {
          dragOverItem.current = index
        }}
        onDragEnd={handleSort}
        onDragOver={(e) => e.preventDefault()}
        key={index}
      >
        <div className="column-action">{_renderSort()}</div>
        <div className="column-title2">{_renderColumn(item.title)}</div>
        <div className="column-title2">{_renderColumn(item.services.join(', '))}</div>
        <div className="column-title2">{_renderColumn(item.detailTitle)}</div>
        <div className="column-title2">{_renderColumn(item.detailDescription)}</div>
        <div className="column-title2">{_renderColumn(item.image)}</div>
        <div className="column-title">{_renderColumn(item.detailImages.join(", "))}</div>
        <div className="column-action">{_renderStatus(item)}</div>
        <div className="column-action">{_renderActive(item)}</div>
      </div>
    )
  };

  return (
    <div>
      {_renderRow()}
      {listProjectSort.map((item: ProjectModel, index: number) => {
        return _renderRowValue(item, index)
      })}
    </div>
  )
};

export default TableProjectList;
