import React from 'react';
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import RouterDom from "./router"

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterDom/>
      </PersistGate>
    </Provider>
  );
};

export default App;
