import {ProjectRequestModel} from "../../models/projectModel";
import {Col, ColorPicker, message, Row} from "antd";
import {TYPE_CHANGE_INPUT} from "../../enum";
import {useState} from "react";
import api from "../../api";
import {RcFile} from "antd/lib/upload/interface";
import FormInputValue from "./InputItem/FormInputValue";
import FormArrayInput from "./InputItem/FormArrayInput";
import FormInputImage from "./InputItem/FormInputImage";
import FormInputImageDetail from "./InputItem/FormInputImageDetail";
import {Color} from "antd/es/color-picker";

interface Props {
  project: ProjectRequestModel;
  onChangeProject: (data: ProjectRequestModel) => void;
}

const FormCreateProject = (props: Props) => {
  const {project, onChangeProject} = props;
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingDetailImages, setLoadingDetailImages] = useState(false);

  const onChangeTitle = (value: string, title: string) => {
    switch (title) {
      case TYPE_CHANGE_INPUT.CLIENT:
        project.title = value;
        break;
      case TYPE_CHANGE_INPUT.EXPERIMENT:
        project.experiment = value;
        break;
      case TYPE_CHANGE_INPUT.SLUG:
        project.slug = value.replace(/[^a-zA-Z0-9_\-]/g, '');
        break;
      case TYPE_CHANGE_INPUT.EXPERIMENT_TITLE:
        project.detailTitle = value;
        break;
      case TYPE_CHANGE_INPUT.EXPERIMENT_DESCRIPTION:
        project.detailDescription = value;
        break;
      case TYPE_CHANGE_INPUT.ORDER:
        project.order = Number(value);
        break;
      case TYPE_CHANGE_INPUT.THUMBNAIL:
        project.image = value;
        break;
      default:
        break;
    }
    onChangeProject({...project});
  };


  const addItem = (value: string) => {
    project.services.push(value);
    onChangeProject({...project});
  };

  const removeItemArray = (value: string, title: string) => {
    switch (title) {
      case TYPE_CHANGE_INPUT.SERVICES:
        project.services = project.services.filter((item) => item !== value);
        break;
      case TYPE_CHANGE_INPUT.THUMBNAIL:
        project.detailImages = project.detailImages.filter((item) => item !== value);
        break;
      default:
        break;
    }
    onChangeProject({...project});
  };

  const onEditItem = (value: string, title: string, index: number) => {
    switch (title) {
      case TYPE_CHANGE_INPUT.SERVICES:
        project.services[index] = value;
        break;
      case TYPE_CHANGE_INPUT.IMAGE:
        project.detailImages[index] = value;
        break;
      default:
        break;
    }
    onChangeProject({...project});
  };

  const onUpload = async (file: RcFile, title: string) => {
    let link = '';
    if (title === TYPE_CHANGE_INPUT.THUMBNAIL) {
      setLoadingImage(true);
    } else {
      setLoadingDetailImages(true);
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      const res = await api.project.uploadFile(formData);
      message.success(`${file.name} succeed to upload.`);
      link = res.data;
      if (title === TYPE_CHANGE_INPUT.THUMBNAIL) {
        project.image = res.data;
      } else {
        project.detailImages.push(res.data);
      }
    } catch (err) {
      message.error(`${file.name} fail to upload.`);
    } finally {
      onChangeProject({...project});
      setLoadingImage(false);
      setLoadingDetailImages(false);
    }
    return link;
  };

  const _removeImage = (title: string, index: number) => {
    if (title === TYPE_CHANGE_INPUT.THUMBNAIL) {
      project.image = '';
    } else {
      project.detailImages.splice(index, 1);
    }
    onChangeProject({...project});
  };

  const _onChangeListService = (data: string[]) => {
    project.services = data;
    onChangeProject({...project});
  };

  const onChangeListImage = (data: string[]) => {
    project.detailImages = data;
    onChangeProject({...project});
  };

  const onChangeColor = (data: Color) => {
    project.titleColor = `#${data.toHex()}`;
    onChangeProject({...project});
  };

  const _renderFormInputColor = (title: string) => {
    return (
      <Col xs={24} md={12}>
        <p className={'title-text'}>{title}</p>
        <ColorPicker
          value={project.titleColor}
          onChangeComplete={(color) => {
            onChangeColor(color)
          }}
        />
        <span className="m-2">{project.titleColor}</span>
      </Col>
    )
  };

  return (
    <div className={'form-container'}>
      <Row gutter={[24, 12]}>
        <FormInputValue
          title={TYPE_CHANGE_INPUT.CLIENT}
          value={project.title}
          onChange={onChangeTitle}
          placeholder={'Please enter the project title here'}
        />
        <FormInputValue
          title={TYPE_CHANGE_INPUT.EXPERIMENT_TITLE}
          value={project.detailTitle}
          onChange={onChangeTitle}
          placeholder={'Please enter the title of sub-page here'}
        />
        <FormArrayInput
          title={TYPE_CHANGE_INPUT.SERVICES}
          values={project.services}
          placeholder={'Please add new service here'}
          addItem={addItem}
          onEditItem={onEditItem}
          removeItemArray={removeItemArray}
          onChangeListService={_onChangeListService}
        />
        {_renderFormInputColor(TYPE_CHANGE_INPUT.TITLE_COLOR)}
        <FormInputValue
          title={TYPE_CHANGE_INPUT.EXPERIMENT_DESCRIPTION}
          value={project.detailDescription}
          onChange={onChangeTitle}
          placeholder={'Please enter the description of sub-page here'}
        />
        <FormInputValue
          title={TYPE_CHANGE_INPUT.SLUG}
          value={project.slug}
          onChange={onChangeTitle}
          placeholder={'Please enter the project slug here'}
        />
        <FormInputImage
          onUpload={onUpload}
          removeImage={_removeImage}
          values={project.image}
          title={TYPE_CHANGE_INPUT.THUMBNAIL}
          loading={loadingImage}
        />
        <FormInputImageDetail
          title={TYPE_CHANGE_INPUT.IMAGE}
          values={project.detailImages}
          onUpload={onUpload}
          removeImage={_removeImage}
          onChangeListImage={onChangeListImage}
          loading={loadingDetailImages}
        />
      </Row>
    </div>
  )
};

export default FormCreateProject;
