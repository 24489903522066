import './project-create-new.scss';
import { useLocation, useNavigate } from "react-router-dom";
import HeaderCreate from "./HeaderCreate";
import { ProjectModel, ProjectRequestModel } from "../../models/projectModel";
import { useEffect, useState } from 'react';
import FormCreateProject from "./FormCreateProject";
import api from "../../api";
import { notification } from "antd";
import { RouterName } from "../../enum/routerEnum";

const initProject: ProjectRequestModel = {
  title: '',
  experiment: 'Default',
  services: [],
  detailTitle: '',
  detailDescription: '',
  detailImages: [],
  image: '',
  order: 0,
  status: false,
  titleColor: '#000000',
  slug: '',
}
const ProjectCreateNew = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState(initProject);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listProject, setListProject] = useState<ProjectModel[]>([]);

  useEffect(() => {
    getAllProject().then();
  }, [])

  const getAllProject = async () => {
    setLoading(true);
    try {
      const res = await api.project.getAllProject();
      const listSort = res.data.sort((a: ProjectModel, b: ProjectModel) => b.order - a.order);
      setListProject(listSort);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: '',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setProject(location.state);
      setIsEdit(true);
      setId(location.state.id);
    } else {
      setProject(JSON.parse(JSON.stringify(initProject)))
    }
  }, [location.pathname]);

  const _goBack = () => {
    navigate(RouterName.dashboard);
  };

  const _onSave = async () => {
    setLoading(true);
    const maxOrder = listProject.length > 0 ? listProject[0].order : -1;
    try {
      if (isEdit) {
        await api.project.updateProject(project, id);
      } else {
        await api.project.createProject({ ...project, order: maxOrder + 1 });
      }
      notification.success({
        message: 'Success',
        duration: 1,
      });
      navigate(RouterName.dashboard);
    } catch (err: any) {
      let errLog = '';
      if (err && err.response && err.response.data && err.response.data.message) {
        errLog = err.response.data.message;
      }
      notification.error({
        message: 'Error',
        description: errLog.toString().replace(',', ', '),
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  }

  const onChangeProject = (projectNew: ProjectRequestModel) => {
    setProject(projectNew);
  };

  return (
    <div className={'project-create-new-container'}>
      <HeaderCreate
        goBack={_goBack}
        onSave={_onSave}
        loading={loading}
      />
      <FormCreateProject
        project={project}
        onChangeProject={onChangeProject}
      />
    </div>
  )
};

export default ProjectCreateNew;
