import React, {useState} from 'react';
import {Button, Form, Input, notification} from 'antd';
import './login.scss';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logIn} from "../../redux/reducers/auth";
import Lottie from 'react-lottie';
import {getSettingGifDefault} from "../../ultils/getSettingGif";
import * as animationData from '../../assets/json/BLOB.json';
import {RouterName} from "../../enum/routerEnum";
import api from "../../api";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onFinish = async () => {
    setLoading(true);
    try {
      const data = {username, password}
      await api.auth.login(data);
      dispatch(logIn(data));
      navigate(RouterName.dashboard);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Username or password is incorrect',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const _renderFormItem = (label: string, children: React.ReactNode) => {
    return (
      <div className={'form-item-container'}>
        <p className={'label'}>{label}</p>
        {children}
      </div>
    )
  };

  return (
    <div className={'login-container'}>
      <Form
        name="basic"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign={"left"}
        className={'form-login-container'}
      >
        <div className={'header-login-container'}>
          <div className={'img-logo'}>
            <Lottie
              options={getSettingGifDefault(animationData)}
              width={'100%'}
              height={'auto'}
              isClickToPauseDisabled
            />
          </div>
          <p>THE OTHER LAB</p>
        </div>
        <p className={'text-welcome'}>Welcome to TOL CMS</p>
        <p className={'text-login'}>Log in to your account</p>
        {_renderFormItem(
          'Username',
          <Input
            className={'input-login'}
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
          />)}
        {_renderFormItem(
          'Password',
          <Input.Password
            className={'input-login'}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />)}
        <Form.Item>
          <Button loading={loading} htmlType="submit" className={'button-login'}>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default Login;
