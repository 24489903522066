interface Props {
  gotoCreateNewProject: () => void;
}

const HeaderCreateProject = (props: Props) => {
  const {gotoCreateNewProject} = props;
  return (
    <div className={'header-list-project'}>
      <p className={'header-text'}>Projects</p>
      <button
        className={'button-create-new'}
        onClick={gotoCreateNewProject}
      >
        Create
      </button>
    </div>
  )
};

export default HeaderCreateProject;

