import {Navigate} from "react-router-dom"
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reducers";
import {RouterName} from "../../enum/routerEnum";

interface Props {
  redirect?: string
}

const NotFoundRouter = (props: Props) => {
  const {redirect} = props;
  const token = useSelector((state: RootState) => state.auth.username);
  let source = RouterName.login;
  if(token) {
    source = RouterName.dashboard;
  }
  return <Navigate to={redirect ?? source}/>
}
export default NotFoundRouter
