export const TYPE_CHANGE_INPUT = {
  CLIENT: 'CLIENT',
  EXPERIMENT: 'EXPERIMENT',
  SERVICES: 'SERVICES',
  EXPERIMENT_TITLE: 'EXPERIMENT_TITLE',
  EXPERIMENT_DESCRIPTION: 'EXPERIMENT_DESCRIPTION',
  THUMBNAIL: 'THUMBNAIL',
  IMAGE: 'IMAGE',
  ORDER: 'ORDER',
  TITLE_COLOR: 'TITLE_COLOR',
  SLUG: 'SLUG'
}
