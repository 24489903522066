import {applyMiddleware, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import rootReducer from './reducers/index';
import logger from 'redux-logger';
import {persistConfig} from "../config/reduxPersist";

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: any = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);

const persistor = persistStore(store);

export {store, persistor};
