import {ProjectModel} from './../models/projectModel';
import client from "./apiConfig";
import {ProjectRequestModel} from "../models/projectModel";
import {AppSettings} from "./api.seting";
import {store} from "../redux/store";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async getAllProject() {
    return await client.get(AppSettings.API_GET_ALL_PROJECT);
  },
  async createProject(data: ProjectRequestModel) {
    return await client.post(AppSettings.API_GET_ALL_PROJECT, data, {
      auth: store.getState().auth,
    });
  },
  async deleteProject(id: number) {
    return await client.delete(`${AppSettings.API_GET_ALL_PROJECT}/${id}`, {
      auth: store.getState().auth,
    });
  },
  async updateProject(data: ProjectRequestModel, id: number) {
    return await client.put(`${AppSettings.API_GET_ALL_PROJECT}/${id}`, data, {
      auth: store.getState().auth,
    });
  },
  async updateMultipleProject(data: ProjectModel[]) {
    return await client.put(`${AppSettings.API_GET_ALL_PROJECT}`, data, {
      auth: store.getState().auth,
    });
  },
  async uploadFile(value: FormData) {
    return await client.post(AppSettings.API_GET_UPLOAD_IMAGE_PROJECT, value, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      auth: store.getState().auth,
    });
  }
};
