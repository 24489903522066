const config = {
  axiosConfig: () => {
    return {
      baseURL: process.env.REACT_APP_API_CLIENT,
      timeout: 20000,
      responseType: 'json',
    }
  },
  version: "1.0.0"
}
export default config;
