import {combineReducers} from 'redux';
import {AuthState} from "./auth";
import auth from "./auth"

export type RootState = {
  auth: AuthState
}

const rootReducer = combineReducers({
  auth
});

export default rootReducer;
