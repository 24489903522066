export const LIST_SERVICE_DEFAULT = [
  {value: "CREATIVE DIRECTION", label: "CREATIVE DIRECTION"},
  {value: "BRAND DESIGN", label: "BRAND DESIGN"},
  {value: "INTERACTION DESIGN", label: "INTERACTION DESIGN"},
  {value: "CHARACTER DESIGN", label: "CHARACTER DESIGN"},
  {value: "MOTION DESIGN", label: "MOTION DESIGN"},
  {value: "PRODUCTION", label: "PRODUCTION"},
  {value: "FRONT-END", label: "FRONT-END"},
  {value: "BACK-END", label: "BACK-END"},
  {value: "UI.UX", label: "UI.UX"},
  {value: "WEB3", label: "WEB3"},
]

export const STATUS_VALUE = {
  ACTIVE: true,
  NOT_ACTIVE: false,
}
