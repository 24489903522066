import {Button, Col, Upload} from "antd";
import {RcFile} from "antd/lib/upload/interface";
import {DeleteFilled} from "@ant-design/icons";
import {ICON_UPLOAD} from "../../../assets";

interface Props {
  title: string;
  values: string;
  onUpload: any;
  removeImage: (title: string, value: number) => void;
  loading: boolean;
}

const FormInputImage = (props: Props) => {
  const {title, values, onUpload, removeImage, loading} = props;
  const lastIndex = values.lastIndexOf("-");
  const slicedString = values.slice(lastIndex + 1);

  const _renderImage = (title: string, item: string) => {
    return (
      <div className={'image-item-upload'}>
        <div className={'d-flex'}>
          <p className={'style-text-limit-number-line1'}>{item}</p>
        </div>
        <DeleteFilled className={'icon-remove'} onClick={() => removeImage(title, 0)}/>
      </div>
    )
  };
  return (
    <Col xs={24} md={12}>
      <p className={'title-text'}>{title}</p>
      <Upload
        action={(file: RcFile) => onUpload(file, title)}
        fileList={[]}
        // accept={'application/json'}
      >
        <Button
          icon={<img alt={''} src={ICON_UPLOAD}/>}
          className={'upload-button'}
          loading={loading}
        >Upload</Button>

      </Upload>
      {values !== '' && _renderImage(title, slicedString)}
    </Col>
  )
};

export default FormInputImage;

