import './sider-layout.scss';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logOut} from "../../redux/reducers/auth";
import Lottie from "react-lottie";
import {getSettingGifDefault} from "../../ultils/getSettingGif";
import * as animationData from "../../assets/json/BLOB.json";
import React from "react";
import {RouterName} from "../../enum/routerEnum";

const SiderLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigate(RouterName.login);
    dispatch(logOut());
  }

  return (
    <div className={'sider-layout-container'}>
      <div className={'logo-container'}>
        <div className={'img-logo'}>
          <Lottie
            options={getSettingGifDefault(animationData)}
            width={'100%'}
            height={'auto'}
            isClickToPauseDisabled
          />
        </div>
        <p className={'text-logo'}>THE OTHER LAB</p>
      </div>
      <p className={'content-text'}>Content manager</p>
      <div className={'button-logout-container'}>
        <button className={'button-logout'} onClick={handleLogout}>Log Out</button>
      </div>
    </div>
  )
};

export default SiderLayout;
