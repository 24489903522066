import {Col, Input} from "antd";

const {TextArea} = Input;

interface Props {
  title: string,
  value: string,
  onChange: (value: string, title: string) => void,
  placeholder: string,
}

const FormInputValue = (props: Props) => {
  const {placeholder, onChange, title, value} = props;
  return (
    <Col xs={24} md={12}>
      <p className={'title-text'}>{title}</p>
      <TextArea
        value={value}
        onChange={(e: any) => onChange(e.target.value, title)}
        className={'text-input'}
        autoSize={{minRows: 1.2, maxRows: 3}}
        style={{
          minHeight: '40px',
          paddingTop: "8px"
        }}
        placeholder={placeholder}
      />
    </Col>
  )
};

export default FormInputValue;
