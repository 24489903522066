import {Image, Modal} from "antd";
import {IMG_WARNING} from "../../assets";
import './list-project.scss';

interface Props {
  onCancel: () => void;
  onOk: () => void;
}

const ModalConfirm = (props: Props) => {
  const {onCancel, onOk} = props;
  return (
    <Modal
      open={true}
      className={'modal-confirm-remove'}
      footer={[]}
      closeIcon={false}
    >
      <p className={'text-confirm'}>Confirmation</p>
      <Image src={IMG_WARNING} alt={''} className={'icon-warning'}/>
      <p>Are you sure you want to delete this record?</p>
      <div className={'group-button-container'}>
        <button className={'button-close-modal'} onClick={onCancel}>Cancel</button>
        <button className={'button-confirm-modal'} onClick={onOk}>Confirm</button>
      </div>
    </Modal>
  )
};

export default ModalConfirm;
