import {Layout} from "antd";
import Sider from "antd/es/layout/Sider";
import './dashboard.scss';
import {Content} from "antd/es/layout/layout";
import SiderLayout from "../../components/SiderLayout";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reducers";
import {RouterName} from "../../enum/routerEnum";

const Dashboard = () => {
  const token = useSelector((state: RootState) => state.auth.username);
  const navigate = useNavigate();
  useEffect(() => {
    if(!token) {
      navigate(RouterName.login);
    }
  }, []);
  return (
    <Layout hasSider className={'layout-container'} >
      <Sider className={'sider-container'}>
        <SiderLayout/>
      </Sider>
      <Content className={'content-dashboard'}>
        <Outlet></Outlet>
      </Content>
    </Layout>
  );
};

export default Dashboard;
