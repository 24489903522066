import {Col, Input, Select} from "antd";
import {DeleteFilled, HolderOutlined, PlusSquareFilled} from "@ant-design/icons";
import {useRef, useState} from "react";
import {LIST_SERVICE_DEFAULT} from "../../../constants";

interface Props {
  title: string,
  values: string[],
  placeholder: string,
  addItem: (title: string) => void;
  onEditItem: (value: string, title: string, index: number) => void;
  removeItemArray: (item: string, title: string) => void;
  onChangeListService: (data: string[]) => void;
}

const FormArrayInput = (props: Props) => {
  const {
    title,
    onChangeListService,
    placeholder,
    values,
    addItem,
    onEditItem,
    removeItemArray,
  } = props;
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);
  const [service, setService] = useState('');

  const handleSort = () => {
    let listData = values;
    const dragItemNew = listData.splice(dragItem.current, 1)[0]
    listData.splice(dragOverItem.current, 0, dragItemNew)
    dragItem.current = null
    dragOverItem.current = null
    onChangeListService(listData);
  };

  const _renderInputItem = (item: string, title: string, index: number, max: number) => {
    return (
      <div
        className={'text-input-array-value'}
        draggable='true'
        onDragStart={(e) => {
          dragItem.current = index
        }}
        onDragEnter={(e) => {
          dragOverItem.current = index
        }}
        onDragEnd={handleSort}
        onDragOver={(e) => e.preventDefault()}
        key={index}
      >
        <div className={'w-100 d-flex'}>
          <HolderOutlined className={'icon-sort'}/>
          <Input
            value={item}
            onChange={(e: any) => onEditItem(e.target.value, title, index)}
            className={'w-100 border-0'}
          />
        </div>
        <DeleteFilled className={'icon-remove'} onClick={() => removeItemArray(item, title)}/>
      </div>
    )
  };

  const _addItem = () => {
    if (service) {
      addItem(service);
      setService('');
    }
  };

  return (
    <Col xs={24} md={12}>
      <p className={'title-text'}>{title}</p>
      <div className={'d-flex'}>
        <Select
          className={'select-option-services'}
          placeholder={placeholder}
          value={service}
          onChange={(e) => setService(e)}
        >
          {LIST_SERVICE_DEFAULT.map((item, index) => {
            return (
              <Select.Option
                key={item.value}
                className={'text-input'}
                children={<div>{item.label}</div>}
              />
            )
          })}
        </Select>
        <PlusSquareFilled className={'icon-plus'} onClick={_addItem}/>
      </div>
      {values.map((item: string, index: number) => {
        return _renderInputItem(item, title, index, values.length);
      })}
    </Col>
  )
};

export default FormArrayInput;
