import './list-project.scss';
import TableProjectList from './TableProjectList';
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import HeaderCreateProject from "./HeaderProject";
import api from "../../api";
import { useEffect, useState, memo } from "react";
import { ProjectModel } from "../../models/projectModel";
import ModalConfirm from "./ModalConfirm";
import { RouterName } from "../../enum/routerEnum";

const ProjectList = () => {
  const navigate = useNavigate();
  const [listProject, setListProject] = useState<ProjectModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projectRemove, setProjectRemove] = useState<ProjectModel>({} as ProjectModel);

  useEffect(() => {
    getAllProject().then();
  }, []);

  const _gotoCreateNewProject = () => {
    navigate(RouterName.createProject);
  };

  const _removeProject = (data: ProjectModel) => {
    setProjectRemove(data);
    setShowModal(true);
  };

  const _gotoDetail = (data: ProjectModel) => {
    navigate(RouterName.createProject, {
      state: data
    });
  };

  const _onChangeStatus = async (data: ProjectModel) => {
    setLoading(true);
    try {
      await api.project.updateProject({ ...data, status: !data.status }, data.id);
      notification.success({
        message: 'Success',
        description: 'Update successful!',
        duration: 1,
      })
      getAllProject().then();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Update failed!',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  };

  const _updateList = async (data: ProjectModel[]) => {
    setLoading(true);
    try {
      const dataUpdate = data.map((item, index) => {
        return {...item, order: index}
      });
      await api.project.updateMultipleProject(dataUpdate);
      notification.success({
        message: 'Success',
        description: 'Update successful!',
        duration: 1,
      });
      getAllProject().then();
    }catch(err) {
      notification.error({
        message: 'Error',
        description: 'Update failed!',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  };

  const getAllProject = async () => {
    setLoading(true);
    try {
      const res = await api.project.getAllProject();
      const listSort = res.data.sort((a: ProjectModel, b: ProjectModel) => a.order - b.order);
      setListProject(listSort);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: '',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setProjectRemove({} as ProjectModel);
    setShowModal(false);
  };

  const onRemoveProject = async () => {
    setLoading(true);
    try {
      await api.project.deleteProject(projectRemove.id);
      notification.success({
        message: 'Success',
        description: 'Project deletion successful!',
        duration: 1,
      })
      onCancel();
      getAllProject().then();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Project deletion failed!',
        duration: 1,
      })
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={'list-project-container'}>
      <HeaderCreateProject
        gotoCreateNewProject={_gotoCreateNewProject}
      />
      <TableProjectList
        listProject={listProject}
        removeProject={_removeProject}
        gotoDetail={_gotoDetail}
        loading={loading}
        onChangeStatus={_onChangeStatus}
        updateList={_updateList}
      />
      {showModal && projectRemove && <ModalConfirm onCancel={onCancel} onOk={onRemoveProject} />}
    </div>
  )
};

export default memo(ProjectList);
