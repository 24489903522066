import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button} from "antd";

interface Props {
  goBack: () => void;
  onSave: () => void;
  loading: boolean;
}

const HeaderCreate = (props: Props) => {
  const {goBack, onSave, loading} = props;
  return (
    <div className={'header-list-project'}>
      <p className={'header-text'} onClick={goBack}>
        <ArrowLeftOutlined className={'icon-left'}/>
        Back
      </p>
      <Button
        className={'button-save'}
        onClick={onSave}
        loading={loading}
        style={{
          color: 'white !important'
        }}
      >
        Save
      </Button>
    </div>
  )
};

export default HeaderCreate;

