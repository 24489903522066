import { createSlice } from '@reduxjs/toolkit'
import {LoginRequest} from "../../models/oauthModel";

export interface AuthState {
  username: string,
  password: string,
}

const initialState = { username: '', password: '' } as AuthState

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn(state, action) {
      const data: LoginRequest = action.payload;
      state.username = data.username;
      state.password = data.password;
    },
    logOut(state) {
      state.username = initialState.username;
      state.password = initialState.password;
    },
  },
})

export const { logOut, logIn } = auth.actions
export default auth.reducer
