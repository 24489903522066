import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import NotFoundRouter from "./NotFoundRouter"
import Login from "../screen/Login";
import Dashboard from "../screen/Dashboard";
import ProjectList from "../screen/ProjectList";
import ProjectCreateNew from "../screen/ProjectCreateNew";

const RouterDom = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFoundRouter/>}/>
        <Route path={'/'} element={<Dashboard/>}>
          <Route path={''} element={<ProjectList/>}/>
          <Route path={'create-new-project/*'} element={<ProjectCreateNew/>}/>
        </Route>
        <Route path={'/login'} element={<Login/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default RouterDom
