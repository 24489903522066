import {Button, Col, Upload} from "antd";
import {RcFile} from "antd/lib/upload/interface";
import {DeleteFilled, HolderOutlined} from "@ant-design/icons";
import {useRef} from "react";
import {ICON_UPLOAD} from "../../../assets";

interface Props {
  title: string;
  values: string[];
  onUpload: any;
  removeImage: (title: string, index: number) => void;
  onChangeListImage: (data: string[]) => void;
  loading: boolean;
}

const FormInputImageDetail = (props: Props) => {
  const {title, values, onUpload, removeImage, onChangeListImage, loading} = props;
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const handleSort = () => {
    let listData = values;
    const dragItemNew = listData.splice(dragItem.current, 1)[0]
    listData.splice(dragOverItem.current, 0, dragItemNew)
    dragItem.current = null
    dragOverItem.current = null
    onChangeListImage(listData);
  };

  const _renderItemImage = (title: string, item: string, index: number, max: number) => {
    const lastIndex = item.lastIndexOf("-");
    const slicedString = item.slice(lastIndex + 1);
    return (
      <div
        key={index}
        className={'image-item-upload'}
        draggable='true'
        onDragStart={(e) => {
          dragItem.current = index
        }}
        onDragEnter={(e) => {
          dragOverItem.current = index
        }}
        onDragEnd={handleSort}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className={'w-100 d-flex'}>
          <HolderOutlined className={'icon-sort'}/>
          <p className={'style-text-limit-number-line1'}>{slicedString}</p>
        </div>
        <DeleteFilled className={'icon-remove'} onClick={() => removeImage(title, index)}/>
      </div>
    );
  };

  return (
    <Col xs={24} md={12}>
      <p className={'title-text'}>{title}</p>
      <Upload
        action={(file: RcFile) => onUpload(file, title)}
        fileList={[]}
        // accept="image/png, image/jpeg, application/json"
      >
        <Button
          icon={<img alt={''} src={ICON_UPLOAD}/>}
          className={'upload-button'}
          loading={loading}
        >Upload</Button>

      </Upload>
      {values.map((item, index) => {
        return _renderItemImage(title, item, index, values.length)
      })}
    </Col>
  )
};

export default FormInputImageDetail;
